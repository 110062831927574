import 'bootstrap'
require('datatables.net-bs4')

// JavaScript

// datatables
$(document).ready( function () {
  //DataTable
  $('#product-table').DataTable( {
    responsive: true,
    "scrollX": true,
    language: {
      search: "_INPUT_",
      searchPlaceholder: "請輸入關鍵字",
      "emptyTable":     "無資料可以顯示。",
      "info":           "顯示從 _START_ 到 _END_ ，總共 _TOTAL_ 筆資料",
      "infoEmpty":      "Showing 0 to 0 of 0 entries",
      "lengthMenu":     "顯示 _MENU_ 行",
      "loadingRecords": "處理中...",
      "processing":     "處理中...",
      "zeroRecords":    "沒有結果。",
      "paginate": {
        "first":      "第一頁",
        "last":       "最後一頁",
        "next":       "下一頁",
        "previous":   "上一頁"
      },
    },
    "pageLength": 20,
    "destroy": true,
    "order": [[ 4, "desc" ]]
  } );
  $("#searchbox").html($(".dataTables_filter"));
  $("#searchbox input").addClass('form-select').removeClass('form-control form-control-sm');
} );
